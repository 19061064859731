import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import company_logo from './images/racestreaming_logo.svg';

const useStyles = makeStyles((theme) => ({
	Logo: {
		height: 95,
	},
}));

export default function Logo(props) {
	const classes = useStyles();

	let link = 'https://live.racestreaming.com';

	// eslint-disable-next-line no-useless-escape
	return (
		<a href={link} className={classes.Logo} target="_blank" rel="noopener noreferrer">
			<img width="100px" height="100px" src={company_logo} alt="racestreaming.com" />
		</a>
	);
}
