import React from 'react';

import makeStyles from '@mui/styles/makeStyles';

import company_logo from './images/racestreaming_logo.svg';

const useStyles = makeStyles((theme) => ({
	Logo: {
		// height: 27,
		filter: 'brightness(0) invert(1)',
		paddingRight: '25px',
		paddingTop: '5px',
	},
}));

export default function Logo(props) {
	const classes = useStyles();

	let link = 'https://racestreaming.com';

	// eslint-disable-next-line no-useless-escape
	return (
		<a href={link} className={classes.Logo} target="_blank" rel="noopener noreferrer">
			<img width="50px" height="50px" src={company_logo} alt="racestreaming.com" />
		</a>
	);
}
